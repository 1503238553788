export const supportNetwork = { 
    1 : {
        name : "ETH",
        chainId : 1,
        rpc : "https://mainnet.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
        symbol : 'ETH'
    },
    'default':{
        name : "ETH",
        chainId : 1,
        rpc : "https://mainnet.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
        symbol : 'ETH'
    }

}

export const RPC_URLS = {
    // 43113 : "https://api.avax-test.network/ext/bc/C/rpc"
    // 56: "https://bsc-dataseed1.defibit.io/",
    // 97: "https://data-seed-prebsc-2-s2.binance.org:8545/",
    // 32520 : "https://mainnet-rpc.brisescan.com/",
    // 40627 : "https://testnet-rpc.cscscan.io/"
    // 13308 : "https://mainnet-rpc.cscscan.io/"
    // 3: "https://ropsten.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
    // 4: "https://rinkeby.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
    // 137 : "https://polygon-rpc.com/",
    1 : "https://mainnet.infura.io/v3/63f4b8ee61284419b46c780d03befc4e"
  };
