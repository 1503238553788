import moment from "moment";
export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;

}

export const formatDate = (unixTime) =>{
  try{
    // let dateString = new Date(unixTime).toLocaleString({ hour12: true });
    let dateString = moment(unixTime).format('lll');
    // let startIndex = dateString.indexOf("(");
    // let modifyDate = dateString.substring(0,startIndex);
    // console.log(modifyDate);
    return dateString;
  }
  catch(err){
    console.log(err.message);
  }
  
}

export const formatDateWithZone = (unixTime) =>{
  try{
    let dateString = new Date(unixTime).toString();
   
    let startIndex = dateString.indexOf("(");
    let modifyDate = dateString.substring(0,startIndex);
    return modifyDate;
  }
  catch(err){
    console.log(err.message);
  }
  
}

export const contract = {
  1: {
    STAKE_ADDRESS: "0xC0edE73e0FABE77a42C38b1a72C6859ce7A3D9Fe",
    MULTICALL_ADDRESS: "0x5e227AD1969Ea493B43F840cfF78d08a6fc17796"
  }
}

export const CHAIN_ID = 1;
