import { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core";
import bscstakeAbi from '../json/stake.json';
import tokenAbi from '../json/token.json';
import { contract } from "./constant";
import { MulticallContractWeb3 } from "./useContracts";
import { getWeb3 } from "./connectors";
import { CHAIN_ID } from "./constant";
import { toast } from "react-toastify";


export const useAccountStats = (updater) => {
    let { chainId, account } = useWeb3React();
    let web3 = getWeb3(CHAIN_ID);


    const [stats, setStats] = useState({
        totalStaked: 0,
        totalUnStaked: 0,
        totalClaimed: 0,
        StakedCount: 0,
        tokenName: '',
        tokenSymbol: '',
        tokenDecimals: 0,
        isApproved : false,
        stakeTokenBalance : 0,
        stakeHistory : []
    });

    const mc = MulticallContractWeb3(CHAIN_ID);
    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[CHAIN_ID].STAKE_ADDRESS);



    useEffect(() => {
        const fetch = async () => {
            try {
                let stakeHistory = [];
                const data = await mc.aggregate([
                    stakeContract.methods.Carbon(), //0
                    stakeContract.methods._stakers(account), //1
                    stakeContract.methods.getList(account) //2
                ]);

                let tokenContract = new web3.eth.Contract(tokenAbi, data[0]);

                let length = data[2][1];
                if(parseFloat(length) > 0){
                    let getLedgerArray = [];
                    for(let i=0;i<length;i++){
                        getLedgerArray[i] = stakeContract.methods.getLedgerIndex(account,i);
                    }

                    stakeHistory = await mc.aggregate(getLedgerArray);
                }

                const tokenData = await mc.aggregate([
                    tokenContract.methods.name(),
                    tokenContract.methods.symbol(),
                    tokenContract.methods.decimals(),
                    tokenContract.methods.allowance(account,contract[CHAIN_ID].STAKE_ADDRESS),
                    tokenContract.methods.balanceOf(account)
                ]);

                
                
                setStats({
                    totalStaked: data[1][1] / Math.pow(10,tokenData[2]),
                    totalUnStaked : data[1][2] / Math.pow(10,tokenData[2]),
                    totalClaimed: data[1][3] / Math.pow(10,tokenData[2]),
                    StakedCount: length,
                    tokenName: tokenData[0],
                    tokenSymbol: tokenData[1],
                    tokenDecimals: tokenData[2],
                    isApproved : tokenData[3] / Math.pow(10,tokenData[2]) > 1000000000000 ? true : false,
                    stakeTokenBalance : tokenData[4] / Math.pow(10,tokenData[2]),
                    stakeHistory
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (mc && account) {
            fetch();
        }
        else {
            setStats({
                totalAmount: 0,
                tobeRewarded: 0,
                StakedCount: 0,
                tokenName: '',
                tokenSymbol: '',
                tokenDecimals: 0,
                isApproved : false,
                stakeTokenBalance : 0,
                stakeHistory : []
            })
        }
        // eslint-disable-next-line
    }, [updater, chainId, account]);

    return stats;
}
