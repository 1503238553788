import { useEffect, useState } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import { CHAIN_ID, contract, formatDate, formatDateWithZone } from "../hooks/constant";
import { formatPrice, getContract } from "../hooks/contractHelper";
import { useCommonStats } from "../hooks/useCommon";
import { useAccountStats } from "../hooks/useAccount";
import { useWeb3React } from "@web3-react/core";
import { toast } from 'react-toastify';
import tokenAbi from '../json/token.json';
import stakeAbi from '../json/stake.json';
import { ethers } from "ethers";
import { getWeb3 } from "../hooks/connectors";
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css';
import infoImg from '../Assets/img/info.png';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import bscstakeAbi from '../json/stake.json';
import { MulticallContractWeb3 } from "../hooks/useContracts";




function Home() {
    const { account, chainId, library } = useWeb3React();
    const [updater, setUpdater] = useState(new Date());
    const commonStats = useCommonStats(updater);
    const accStats = useAccountStats(updater);
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const [days, setDays] = useState(0);
    const [endDate, setEndDate] = useState(` - `);
    const [endTime, setEndTime] = useState(false);
    const [error, setError] = useState('');
    const [tLoading, setTLoading] = useState(['', false]);
    const [uLoading, setULoading] = useState(['', false]);
    const [slotRevenue, setSlotRevenue] = useState([]);
    const [ereturn, setEreturn] = useState(0);


    const handleApprove = async () => {
        if (account) {
            if (chainId === CHAIN_ID && commonStats.Carbon !== '') {
                try {

                    setLoading(true);

                    let tokenContract = getContract(tokenAbi, commonStats.Carbon, library);
                    let amount = ethers.utils.parseEther('100000000000000000').toString();

                    let tx = await tokenContract.approve(contract[CHAIN_ID].STAKE_ADDRESS, amount, { 'from': account });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(CHAIN_ID);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdater(new Date());
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdater(new Date());
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdater(new Date());
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    console.log(err.message)
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Please select Ethereum Mainnet Network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!');
            setLoading(false);
        }
    }

    const handleChangeAmount = (e) => {
        e.preventDefault();
        setAmount(e.target.value);
        if (isNaN(e.target.value)) {
            setError('please enter valid amount.');
        }
        if (parseFloat(e.target.value) <= 0 || e.target.value === '') {
            setError('amount must be greator than zero.');
        }
        else {
            setError('');
        }
        return;
    }

    const handleMaxAmount = () => {
        parseFloat(accStats.stakeTokenBalance) > parseFloat(commonStats.maxStakeableToken) ?
            setAmount(commonStats.maxStakeableToken) : setAmount(accStats.stakeTokenBalance);
        setError('');
    }

    const handleStake = async () => {
        setLoading(true);
        try {
            if (parseFloat(amount) > 0 && !isNaN(amount)) {
                if (account) {
                    if (chainId === CHAIN_ID) {
                        if (parseFloat(accStats.stakeTokenBalance) >= parseFloat(amount)) {
                            let tokenStakingAddress = contract[CHAIN_ID].STAKE_ADDRESS;
                            let stakeContract = getContract(stakeAbi, tokenStakingAddress, library);
                            let stakeAmount = ethers.utils.parseUnits(amount.toString(), accStats.tokenDecimals);
                            let stakeTime = Math.floor(days * 60 * 60);
                            let tx = await stakeContract.stake(stakeAmount.toString(), stakeTime, { 'from': account });
                            const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                            toast.promise(
                                resolveAfter3Sec,
                                {
                                    pending: 'Waiting for confirmation 👌',
                                }
                            )

                            var interval = setInterval(async function () {
                                let web3 = getWeb3(CHAIN_ID);
                                var response = await web3.eth.getTransactionReceipt(tx.hash);
                                if (response != null) {
                                    clearInterval(interval)
                                    if (response.status === true) {
                                        toast.success('success ! your last transaction is success 👍');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                    else if (response.status === false) {
                                        toast.error('error ! Your last transaction is failed.');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                    else {
                                        toast.error('error ! something went wrong.');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                }
                            }, 5000);

                        }
                        else {
                            toast.error('you don\'t have enough balance !');
                            setLoading(false);
                        }

                    }
                    else {
                        toast.error('Please select Ethereum Mainnet Network !');
                        setLoading(false);
                    }
                }
                else {
                    toast.error('Please Connect Wallet!');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Enter Valid Amount !');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.reason);
            setLoading(false);
        }
    }

    const handleTerminate = async (index) => {
        setTLoading([index, true]);
        try {

            if (account) {
                if (chainId === CHAIN_ID) {
                    let tokenStakingAddress = contract[CHAIN_ID].STAKE_ADDRESS;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, library);

                    let tx = await stakeContract.terminate(index, { 'from': account });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(CHAIN_ID);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success.');
                                setUpdater(new Date());
                                setTLoading(['', false]);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(new Date());
                                setTLoading(['', false]);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(new Date());
                                setTLoading(['', false]);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Ethereum Mainnet Network !');
                    setTLoading(['', false]);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setTLoading(['', false]);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setTLoading(['', false]);
        }
    }

    const handleUnstake = async (index) => {
        setULoading([index, true]);
        try {

            if (account) {
                if (chainId === CHAIN_ID) {
                    let tokenStakingAddress = contract[CHAIN_ID].STAKE_ADDRESS;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, library);

                    let tx = await stakeContract.claimAndUnstake(index, { 'from': account });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(CHAIN_ID);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success.');
                                setUpdater(new Date());
                                setULoading(['', false]);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(new Date());
                                setULoading(['', false]);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(new Date());
                                setULoading(['', false]);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Ethereum Mainnet Network !');
                    setULoading(['', false]);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setULoading(['', false]);
            }
        }
        catch (err) {
            toast.error(err.reason);
            setULoading(['', false]);
        }
    }


    useEffect(() => {
        function fecth() {
            if(days > 0){
                var Days = Math.floor(days / 24);
                var Remainder = days % 24;
                var Hours = Math.floor(Remainder);
                setEndDate(`${Days} Days ${Hours} Hours`)
                const timeElapsed = Date.now();
                setEndTime(parseInt(timeElapsed) + parseInt(days * 60 * 60 * 1000))
            }
            else{
                setEndDate(` - `);
                setEndTime(false)
            }
        }
        fecth();
    }, [days])

    useEffect(() => {
        if(parseFloat(days) > 0){
            setEreturn(parseFloat(amount) + parseFloat((amount * parseFloat(commonStats.getApy) * (days * 3600))) / (3153600000));
        }
        else{
            setEreturn(0);
        }
        // eslint-disable-next-line
    }, [amount, days,commonStats])


    useEffect(() => {
        async function fetch() {
            if (account) {
                let web3 = getWeb3(CHAIN_ID);
                const mc = MulticallContractWeb3(CHAIN_ID);
                let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[CHAIN_ID].STAKE_ADDRESS);
                let length = accStats.stakeHistory.length;
                let getRevenueArray = [];

                for (let i = 0; i < length; i++) {
                    getRevenueArray[i] = stakeContract.methods.slotRevenue(account, i);
                }

                let revenueHistory = await mc.aggregate(getRevenueArray);
                setSlotRevenue(revenueHistory);
            }
            else {
                setSlotRevenue([]);
            }

        }
        if (account) {
            fetch();

            setInterval(() => {
                fetch();
            }, 10000);
        }
    }, [accStats, account])

    return (
        <>
            <section className="banner-section">
                <div className="bg-layer"></div>
                <Container>
                    <Row>
                        <Col xs={12} md={6} lg={7}>
                            <h1>CARBON12 Staking Dashboard</h1>
                            <a href="#staking-section" className="theme-btn">Stake CARBON12</a>
                            <h4>Powered by <img src={require('../Assets/img/ethereum.svg').default} alt="eth" /> <span>Ethereum.</span></h4>
                        </Col>
                        <Col xs={12} md={6} lg={5}>
                            <div className="stake-box">
                                <div className="staking-info">
                                    <div className="all-info">
                                        <div className="label-1 text1">CARBON12 STAKED</div>
                                        <div className="credit-stacked"><span className="highlight-span">{commonStats.totalStaked ? formatPrice(commonStats.totalStaked) : 0} {commonStats.tokenSymbol ? commonStats.tokenSymbol : '...'}</span></div>
                                    </div>
                                    <div className="all-info">
                                        <div className="label-1 text1">CARBON12 STAKERS</div>
                                        <div className="credit-stacked"><span className="highlight-span">{commonStats.totalStakers ? commonStats.totalStakers : 0}</span></div>
                                    </div>
                                    <div className="all-info">
                                        <div className="label-1 text1">CURRENT APY</div>
                                        <div className="credit-stacked"><span className="highlight-span">{commonStats.getApy ? parseFloat(commonStats.getApy).toFixed(4) : 0} %</span></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="calculator-section" id="staking-section">
                <div className="curve-left">
                    <img src={require('../Assets/img/why-token-curve-left.png').default} alt="curve-left" />
                </div>
                <div className="curve-right">
                    <img src={require('../Assets/img/why-token-curve-right.png').default} alt="curve-right" />
                </div>
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <div className="calc-box">
                                <h3>$CARBON12 STAKE</h3>
                                <p>Stake CARBON12 get upto <span className="highlight-span">{commonStats.getApy ? parseFloat(commonStats.getApy).toFixed(4) : 0}% APY </span></p>
                                <p>CARBON12 Balance : <span className="highlight-span">{accStats.stakeTokenBalance ? formatPrice(accStats.stakeTokenBalance) : 0} {accStats.tokenSymbol}</span></p>
                                <div className="add-liquidity">
                                    <div className="content">

                                        <div className="d-flex align-items-center justify-content-between">

                                            <div className="labels">
                                                <h6>CARBON12</h6>
                                            </div>
                                            <div className="labels1 d-flex">
                                                <input type="text" value={amount} onChange={(e) => handleChangeAmount(e)} placeholder="5000" />
                                                <Button className="input-button" onClick={() => handleMaxAmount()}>Max</Button>
                                            </div>

                                        </div>
                                        <p className='text-danger error-text'><small>{error}</small></p>

                                    </div>
                                </div>

                                <div className="col-12 d-flex justify-content-center">
                                    <button class={`btn-box ${days === 8760 ? 'active' : ''}`} onClick={() => setDays(8760)}>1 Year</button>
                                    <button class={`btn-box ${days === 17520 ? 'active' : ''}`} onClick={() => setDays(17520)}>2 Year</button>
                                    <button class={`btn-box ${days === 26280 ? 'active' : ''}`} onClick={() => setDays(26280)}>3 Year</button>
                                    <button class={`btn-box ${days === 35040 ? 'active' : ''}`} onClick={() => setDays(35040)}>4 Year</button>
                                    <button class={`btn-box ${days === 43800 ? 'active' : ''}`} onClick={() => setDays(43800)}>5 Year</button>
                                </div>



                                <Slider
                                    progress
                                    min={0}
                                    step={1}
                                    max={43800}
                                    defaultValue={0}
                                    tooltip={true}
                                    value={days}
                                    format={value => `${value} Hours`}
                                    onChange={value => {
                                        setDays(value);
                                    }}
                                    className="mt-5"
                                />
                                <div className="d-flex justify-content-between">
                                    <div className="labels">
                                        <h6>0 Year</h6>
                                    </div>
                                    <div className="labels">
                                        <h6>5 Year</h6>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-3">
                                    <div className="labels">
                                        <h6>Estimated Return
                                            <OverlayTrigger
                                                key="top"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        Estimated return after endtime period.
                                                    </Tooltip>
                                                }
                                            >
                                                <img className="mx-1" height="20" width="20" src={infoImg} alt="info" />
                                            </OverlayTrigger></h6>
                                    </div>
                                    <div className="labels">
                                        <h6>{ereturn ? `${formatPrice(ereturn)} ${commonStats.tokenSymbol ? commonStats.tokenSymbol : ''}` : '-'} </h6>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="labels">
                                        <h6>Lock Days</h6>
                                    </div>
                                    <div className="labels">
                                        <h6>{endDate ? endDate : '-'}</h6>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="labels">
                                        <h6>Lock Until Date</h6>
                                    </div>
                                    <div className="labels">
                                        <h6>{endTime ? formatDateWithZone(endTime) : ' - '}</h6>
                                    </div>
                                </div>

                                {/* <div className="plus-sys">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
                                </div>
                                <h4>locked until {endDate ? endDate.toUTCString() : ' - '}</h4> */}
                                {
                                    accStats.isApproved ? (
                                        <Button className="theme-btn w-100 mt-3" onClick={() => !loading ? handleStake() : null}>{loading ? 'Loading…' : 'Stake'}</Button>
                                    ) : (
                                        <Button className="theme-btn w-100 mt-3" onClick={() => !loading ? handleApprove() : null}>{loading ? 'Loading…' : 'Approve'}</Button>
                                    )
                                }

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="details-section">
                <Container>
                    <Row>
                        <Col sm={12} md={6} lg={4}>
                            <div className="details-box">
                                <div className="title">Total Staked</div>
                                <h4>{accStats.totalStaked ? formatPrice(accStats.totalStaked) : 0} {commonStats.tokenSymbol ? commonStats.tokenSymbol : '...'}</h4>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div className="details-box">
                                <div className="title">Total Unstaked</div>
                                <h4>{accStats.totalUnStaked ? formatPrice(accStats.totalUnStaked) : 0} {commonStats.tokenSymbol ? commonStats.tokenSymbol : '...'}</h4>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div className="details-box">
                                <div className="title">Total Claimed</div>
                                <h4>{accStats.totalClaimed ? formatPrice(accStats.totalClaimed) : 0} {commonStats.tokenSymbol ? commonStats.tokenSymbol : '...'}</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm={12}>
                            <h3 className="text-white">Transaction Records</h3>
                            <Table bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Staked Amount</th>
                                        <th>APY</th>
                                        <th>Reward</th>
                                        <th>Start Date(LocalTime)</th>
                                        <th>End Date(LocalTime)</th>
                                        <th>Terminate
                                            <OverlayTrigger
                                                key="top"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        You can terminate stake before lock period and get back only staked amount in wallet instantly.
                                                    </Tooltip>
                                                }
                                            >
                                                <img className="mx-1" height="20" width="20" src={infoImg} alt="info" />
                                            </OverlayTrigger>
                                        </th>
                                        <th>Unstake & Claim
                                            <OverlayTrigger
                                                key="top"

                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        You can unstake after endtime and get back staked amount with reward in wallet instantly.
                                                    </Tooltip>
                                                }
                                            >
                                                <img className="mx-1" height="20" width="20" src={infoImg} alt="info" />
                                            </OverlayTrigger>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {accStats.stakeHistory.length > 0 ? (accStats.stakeHistory.map((row, index) => {
                                        let enddate = parseInt(row[0]._stakeTime) + parseInt(row[0]._lockTime);

                                        return (
                                            <tr key={index}>
                                                <td>{row[0]._amount ? formatPrice(row[0]._amount / Math.pow(10, accStats.tokenDecimals)) : 0} {accStats.tokenSymbol ? accStats.tokenSymbol : ' - '}</td>
                                                <td>{row[0]._apy ? parseFloat(row[0]._apy / Math.pow(10, 11)).toFixed(4) : 0}%</td>
                                                <td>{slotRevenue.length > 0 && slotRevenue[index] ? formatPrice(slotRevenue[index] / Math.pow(10, accStats.tokenDecimals)) : 'loading...'} {accStats.tokenSymbol ? accStats.tokenSymbol : ' - '}</td>
                                                <td>{row[0]._stakeTime ? formatDate(row[0]._stakeTime * 1000) : ' - '}</td>
                                                <td>{row[0]._lockTime ? formatDate(enddate * 1000) : ' - '}</td>
                                                <td>
                                                    <Button onClick={() => handleTerminate(index)} disabled={tLoading[0] === index && tLoading[1] ? true : false} type="button" className="theme-btn1">
                                                        {tLoading[0] === index && tLoading[1] ? 'Loading...' : 'Terminate'}
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button onClick={() => handleUnstake(index)} disabled={uLoading[0] === index && uLoading[1] ? true : false} type="button" className="theme-btn1">
                                                        {uLoading[0] === index && uLoading[1] ? 'Loading...' : 'Unstake'}
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })

                                    ) : (
                                        <tr>
                                            <td colSpan={7}>
                                                <h5 className="m-0 text-white">You Have No Stake Record Yet.</h5>
                                            </td>
                                        </tr>
                                    )
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container >
            </section >
        </>
    )
}

export default Home
