import { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core";
import bscstakeAbi from '../json/stake.json';
import tokenAbi from '../json/token.json';
import { contract } from "./constant";
import { MulticallContractWeb3 } from "./useContracts";
import { getWeb3 } from "./connectors";
import { CHAIN_ID } from "./constant";
import { toast } from "react-toastify";


export const useCommonStats = (updater) => {
    let { chainId } = useWeb3React();
    let web3 = getWeb3(CHAIN_ID);


    const [stats, setStats] = useState({
        Carbon: '',
        denominator: 0,
        getApy: 0,
        paused: true,
        startTime: 0,
        totalDistributed: 0,
        totalStaked: 0,
        totalUnstaked: 0,
        tokenName: '',
        tokenSymbol: '',
        tokenDecimals: 0,
        totalStakers: 0
    });

    const mc = MulticallContractWeb3(CHAIN_ID);
    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[CHAIN_ID].STAKE_ADDRESS);



    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await mc.aggregate([
                    stakeContract.methods.Carbon(), //0
                    stakeContract.methods.PoolStartTime(), //1
                    stakeContract.methods.StakersCount(), //2
                    stakeContract.methods.denominator(), //3
                    stakeContract.methods.getApy(), //4
                    stakeContract.methods.paused(), //5
                    stakeContract.methods.totalDistributed(), //6
                    stakeContract.methods.totalStaked(), //7
                    stakeContract.methods.totalUnstaked() //8
                ]);

                let tokenContract = new web3.eth.Contract(tokenAbi, data[0]);


                const tokenData = await mc.aggregate([
                    tokenContract.methods.name(),
                    tokenContract.methods.symbol(),
                    tokenContract.methods.decimals()
                ]);



                setStats({
                    Carbon: data[0],
                    startTime: data[1],
                    totalStakers: data[2],
                    denominator:data[3],
                    getApy: data[4] / Math.pow(10,11),
                    paused: data[5],
                    totalDistributed: data[6] / Math.pow(10,tokenData[2]),
                    totalStaked:data[7] / Math.pow(10,tokenData[2]),
                    totalUnstaked:data[8] / Math.pow(10,tokenData[2]),
                    tokenName: tokenData[0],
                    tokenSymbol: tokenData[1],
                    tokenDecimals: tokenData[2]
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (mc) {
            fetch();
        }
        else {
            setStats({
                Carbon: '',
                EndTime: 0,
                StakedTimeLimit: 0,
                denominator: 0,
                getApy: 0,
                paused: true,
                startTime: 0,
                totalStaked: 0,
                tokenName: '',
                tokenSymbol: '',
                tokenDecimals: 0,
                totalStakers: 0
            })
        }
        // eslint-disable-next-line
    }, [updater, chainId]);

    return stats;
}
